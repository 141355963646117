.underline {
    text-decoration: underline;
}

.clickable {
    cursor: pointer;
}

.bold {
    font-weight: 500;
}

.italic {
    font-style: italic;
}

.invalid {
    color: #E50000 !important;
}

.disabled {
    color: #b0b0b0;
}

.podLabel {
    user-select: none;
    color: #7D7D7D;
    font-weight: 500;
    margin-right: auto; /* avoids having label span full width */
    margin-bottom: 8px;
}

.link {
    color: #026CCF;
    cursor: pointer;
    outline: none;
}

.link:hover {
    text-decoration: underline;
}

.link:active,
.link:focus {
    color: #0A92FA;
    text-decoration: underline;
}

.labelField {
    min-width: 300px;
    height: 33px;
    border-radius: 4px;
    border: solid 1px;
    box-sizing: border-box;
    background-color: #ffffff;
    float: left;
}

.browseButton {
    width: 111px;
    height: 33px;
    object-fit: contain;
}

.setupscreenlayout {
    padding: 15px;
    text-align: left;
    margin-right: 82px;
}
.renewalsCheckboxTitle {
    margin-left: 15px;
    margin-top:32px;
    font-weight: bold;
    font-size: 16px;
    font-family: Roboto;
}

.renewalsCheckbox label {
    margin-bottom: 0px;
    font-weight: normal;
    font-family: Roboto;
}
.page-header {
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 15px;
    color: #212121;
    border-bottom: none;
}

.upload-configuration-label {
    width: 367px;
    height: 32px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #212121;
}

.upload-configuration {
    margin-left: 15px;
    margin-top: 64px;
}

.white-background {
    background-color: #ffffff;
}

.header-summary {
    font-family: Roboto;
    font-size: 16px;
    line-height: normal;
    text-align: justify;
    color: #212121;
    padding-bottom: 20px;
    margin-right: 82px;
}

#mappingPageSummary {
    padding-bottom: 0px;
}

.formLabel {
    color: #7d7d7d;
    font-weight: bold;
    margin-bottom: 5px;
}

.more {
    -webkit-transform: rotate(90deg));
    transform: rotate(90deg);
    width: 19.6px;
    height: 24px;
    object-fit: contain;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}
.numberSelectionBox {
    width:162px;
}
.more svg { fill: #026CCF; }

.page-header3 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 10px 0;
    color: #212121;
}

.linkCompaniesTableHeader {
    vertical-align: top;
    height: 0px;
}

.linkingRow {
    height: 64px;
    font-size: 14px;
    padding: 5px;
}

.columnHeader {
    font-weight: bold;
    font-size: 18px;
    align-self:center;
}


.rectangle {
    border-radius: 4px;
    border: solid 1px gray;
    margin-right: 20px;
    height: 40px;
}

.noBorder {
    border: none;
    width: 360px;
    margin-top:4px;
}

.clear svg {
    width: 14px;
    height: 14px;
    object-fit: contain;
    box-sizing: border-box;
    fill: #252525;
}

.clear {
    width: 14px;
    height: 14px;
    object-fit: contain;
    box-sizing: border-box;
    fill: #252525;
    margin-right:5px;
    margin-bottom:4px;
}

.vendorAndConfigSelection {
    margin-right: 82px;
}

.pageTop {
    width: 100%;
    height: 550px;
    background: repeating-linear-gradient( 45deg, #0276e3 50%, #026ccf);
}
.pageHeader {
    height: 75px;
    width: 100%;
    background-color: #0276e3;
    align-content:center;
}
.headerLogo {
    position: absolute;
    left: -25px;
    top: -15px;
}
#mappingSpreadsheetFrame {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    max-width: 200px;
    background-color: white;
}
#dataTableContainerOuter {
    padding: 32px;
    background-color: #f5f5f5;
}
#scrollPadding {
    width: 20px;
    background-color: white;
}
#dataTableInner {
    border-spacing:0px;
}

#tableOverflowWrapper {
    background-color:white;
}
.headerPageCounter {
    position: absolute;
    right: 30px;
    top: 25px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.connectWiseLogo {
    margin-left: 63px;
    font-size: 22px;
    padding-top:40px;
}

.statusImage {
    margin-left: 44%;
    margin-right: 56%;
    margin-top: 50px;
}

.statusHeader {
    padding-top: 5px;
    margin-top: 40px;
    font-family: Roboto;
    font-size: 32px;
    font-weight: bold;
    color: white;
    text-align: center;
    line-height: 1em;
    height: 43px;
}

.statusTextSeparator {
    margin-top: 20px;
}

.statusMessage {
    padding-top: 5px;
    margin-top: 20px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: lighter;
    color: white;
    text-align: center;
    line-height: 1em;
    height: 43px;
}

.statusMessage p{
    margin-top: 40px;
}

.pageBottom {
    margin: auto;
    width: 400px;
}

.downloadButton {
    margin-top:50px;
    margin-bottom:30px;
    border-radius:6px;
    background-color:#026CCF;
    cursor:pointer;
    color:white;
    height:60px;
    width:400px;
}

.downloadButtonText {
    padding-top: 20px;
    font-family: Roboto;
    font-size: 24px;
    text-align: center;
}

.dividerBetweenDownloadButtons {
    margin-top: auto;
    margin-bottom: -40px;
    display: block;
}

.disableBg {
    background-color: white;
    z-index: 1;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pageWithoutNavButtons {
    margin-left: 105px;
}
.previewLabel {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #3c3c3c;
    margin-right:6px;
}

.pageBody {
    margin-right: 82px;
}
.startLineControlContainer {
    margin-bottom: 34px;
}

.tableStyle {
    min-width: 150px;
}

.linkedCompanyDropdown {
    padding-top:11px;
    padding-right:5px;
}

.linkedCompany {
    padding-top: 5px;
    padding-left: 4px;
}

.comboBoxNoUnderline input,
.comboBoxNoUnderlineActive input:active,
.comboBoxNoUnderlineFocus input:focus,
.comboBoxNoUnderlineFocus input:disabled {
    box-shadow: 0 0px 0px 0px #7d7d7d;
    -webkit-box-shadow: 0 0px 0px 0px #7d7d7d;
}

.saveButtonEnabled {
    height: 40px;
    width: 40px;
    border: solid 2px #212121;
    border-radius: 50%;
    margin-top: 10px;
}

.saveButtonDisabled {
    height: 40px;
    width: 40px;
    border: solid 2px #b0b0b0;
    border-radius: 50%;
    margin-top: 10px;
}

.saveText {
    margin-top: 4px;
    margin-left: 6px;
    margin-bottom: 0px;
}

#selectMapping {
    padding-left: 0px;
}

#mappingTemplate {
    padding-left: 0px;
    padding-top: 0px;
}

#saveMapping {
    padding-left: 0px;
    padding-top: 0px;
}

.popupOverlay {
    position: fixed; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128,128,128,0.5); /* color */
}