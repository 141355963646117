
.spreadsheetHeaderText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525;
}
.spreadsheetHeaderNumberOffset {
    width: 30px;
    display: inline-block;
}
.spreadsheetHeader {
    border: solid 2px #026ccf;
    border-radius: 4px;
    padding: 5px;
    min-height: 32px;
    min-width: 130px;
    font-weight: normal;
    white-space: nowrap;
    display: inline-block;
}

.dataCellInner {
    display: inline-block;
    vertical-align: middle;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 2px;
    box-sizing: border-box;
}
.dataCellWithRowCount {
    padding: 5px;
    height: 43px;
    max-width: 170px;
    border-top: solid 1px #f5f5f5;
    border-bottom: solid 1px #f5f5f5;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    box-sizing: border-box;
}
.dataCell {
    padding: 5px;
    height: 43px;
    max-width: 130px;
    border-top: solid 1px #f5f5f5;
    border-bottom: solid 1px #f5f5f5;
    text-align: left;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
#dataTableInner {
    border-spacing: 0px;
}

.recommendedOuter {
    min-height: 300px;
    background-color: #f5f5f5;
}
.recommendedInner {
    background-color: white;
}
#recommendedSection {
    padding-top: 30px;
}
#recommended {
    background-color: white;
    border-collapse: collapse;
    border-spacing: 0;
}

.recommendedGrid td {
    padding: 5px 0px 5px 5px;
    margin: 0px 0px 0px 0px;
}

#mappingSpreadsheetFrame {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    max-width: 200px;
    background-color: white;
}
#leftmappingframe {
    width: 260px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 30px;
}
.mappingCell {
    border-bottom: 1px solid #e6e6e6;
}
#mappingTableFrame {
    background-color: #f5f5f5;
    border-spacing: 0px;
}
#rightmappingframe {
    background-color: white;
}
#mappingCancelFrame {
    padding: 0px;
    background-color: gray;
    padding-right: 0px;
}

.clear svg {
    width: 14px;
    height: 14px;
    object-fit: contain;
    box-sizing: border-box;
    fill: #252525;
}
.clear {
    width: 14px;
    height: 14px;
    object-fit: contain;
    box-sizing: border-box;
    fill: #252525;
}

.skippedDataRow td {
    background-color: #f5f5f5;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b0b0b0;
}

.normalDataRow td {
    font-size: 12px;
    background-color: white;
}
.numberCell {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3c3c3c;
    width: 10px;
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px;
}
.dataTableHeader {
    padding: 3px;
    border-bottom: solid 1px #f5f5f5;
    vertical-align: top;
    background-color: white;
}

.cancelFrame {
    height: 100%;
    width: 40px;
    background-color: gray;
}
.cancelFrameSquare {
    width: 40px;
    height: 40px;
    opacity: 0.5;
    background-color: #026ccf;
}


.gridDataText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}