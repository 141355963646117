.backButton {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    height: 40px;
    width: 40px;
    border: solid 2px #212121;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.forwardButton {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 40px;
    width: 40px;
    border: solid 2px #212121;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
}
.forwardButtonDisabled {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 40px;
    width: 40px;
    border: solid 2px #b0b0b0;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
}

.navButtonImage{
    height:22px;
    width:22px;
}

.finalButtonTooltip {
    border: solid 1px black;
    position: absolute;
    right: 27px;
    top: 140px;
    visibility: hidden;
}

.finalButtonDisabled {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    height: 40px;
    width: 40px;
    border: solid 2px #b0b0b0;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
}

.finalButton {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    height: 40px;
    width: 40px;
    border: solid 2px #026ccf;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 20px;
    margin-top: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
    background-color: #026ccf;
}

.finalNavButtonImage {
    fill:white;
}

.finalButton:hover + .finalButtonTooltip {
    visibility: visible;
}