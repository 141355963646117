
.fileLabel {
    color: #4a4a4a;
    font-weight:bold;
    padding-bottom:5px;
}

.fileInput {
    display: none;
    width: 0px;
    height: 0px;
}

.fileImage {
    vertical-align:middle;
    width: 25px;
    height: 25px;
    margin-left: 4px;
}

.fileDialog{
    margin:24px 0px;
    justify-content:space-between;
}

.fileLine {
    width:50%;
    display:inline-block;
}

.fileDialogLabel {
    padding-left: 7px;
    font-size: 16px;
    line-height: 1.5;
    color: #212121;
    overflow: hidden;
    border: none;
    width: 420px;
    height:36px;
}
.CwButton-innerStandardActive {
    height: 40px;
    width: 111px;
    margin-right: 30px;
}
#filenameTextContainer {
    white-space: nowrap;
    text-overflow: ellipsis;
}