.pageButton {
    height: 40px;
    width: 40px;
}

.pageButtonSelected {
    background-color: #e3f1fc;
}

.pageForwardButton {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.pageBar {
    width: 90%;
    display:flex;
    justify-content:space-between;
}

.CwButton-innerLightActive {
    align-self:center;
    margin:auto;
}
.CwButton-innerLightDisabled {
    align-self: center;
    margin: auto;
}
.pageContent {
    width: 90%;
}