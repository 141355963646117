@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
        font-family: 'Roboto'
    }
    .button {
        background-color: #4CAF50;
        border: none;
        border-radius: 6px;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
    }
}
