.popup {
    position: fixed;
    width: 494px;
    height: 212px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: solid 1px #cccccc;
    background-color: #ffffff;
}  
.popup\_inner {  
  position: absolute;  
  left: 25%;  
  right: 25%;  
  top: 25%;  
  bottom: 25%;  
  margin: auto;  
  border-radius: 20px;  
  background: white;
}
.popupShadow {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(33, 33, 33, 0.3);
}
h1 {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    margin-left:40px;
    
}
h2 {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #212121;
    margin-left: 40px;    
}
